<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col
								cols="6"
								lg="3"
								md="3"
								v-for="item in ambients"
								:key="item.MamID">
								<v-card
									class="mx-auto"
									outlined>
									<v-card-title class="justify-center">
										<b>{{item.MamID}}</b>
									</v-card-title>
									<v-card-text class="text-center">
										{{'Capacidad Total: ' + item.MamCapacity}}
										<br>
										{{'Capacidad Actual: ' + (item.MamCapacity - item.PalletInside)}}
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											fab
											@click="showDetail(item)"
											:color="item.PalletInside > 0 ? 'error':'info'"
											width="80"
											height="80"
										>
											<b><h1 style="font-size: 50px;">{{item.PalletInside}}</h1></b>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
							
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>


		<v-dialog
			v-if="dialog"
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			persistent>
			<enter-tunnel-detail @closeDetail="closeDetail" :tunnel="tunnel" :TypeMaturation="TypeMaturation"  ref="coolingTunnelDetail"></enter-tunnel-detail>
		</v-dialog>	
		

	</div>
</template>

<script>
	import _sMaturationAmbientService from "@/services/FrozenProduction/MaturationAmbientService";
	import EnterTunnelDetail from '../FrzMaduration/FrzEnterTunnel/EnterTunnelDetail.vue';
	
	export default {
  		components: { EnterTunnelDetail },
		data() {
			return {
				ambients: [],
				dialog: false,
				tunnel: {},
				TypeMaturation: 3
			}
		},

		methods: {
			showDetail(item) {
				this.dialog = true;
				item.FtmID = item.MamID;
				item.FtmDescription = item.MamName;
				this.tunnel = item;
			},

			closeDetail()
			{
				this.initialize()
				this.dialog = false;
			},

			initialize(){
				let filter = {
					MamID: 0,
					Search: ''
				}
				_sMaturationAmbientService.list(filter, this.$fun.getUserID())
				.then((resp) => {
					if(resp.status == 200)
					{
						this.ambients = resp.data;
						console.log(this.ambients);
					}
				});
			}
		},

		created () {
			this.initialize()
		},
	}
</script>